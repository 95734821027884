<template>
  <div class="details-page__structure">
    <div class="details-title">
      {{ $t(`message.details.${type}.structureTitle`) }}
    </div>
    <div class="details-page__structure-content">
      <div class="body">
        <div class="structure-layers" v-for="(layer, i) in getLayers" :key="`layer-${i}`">
          <div
            class="structure-layers__item"
            v-for="(item, n) in layer.items"
            :key="`layer-${i}-sublayer-${n}-${item.id}`"
          >
            <div class="number">
              {{ isJunctionType ? item.mark : getCurrentNumber(i, n) }}
            </div>
            <div class="content" v-if="type === 'system'">
              <div class="content__left">
                <div class="content__name">{{ item.name }}</div>
                <div class="content__material">{{ item.material.name }}</div>
              </div>
              <div class="value" v-if="item.material.valueType && item.material.value !== 0">
                {{ item.material.value }} {{ $t('message.units.mm') }}
              </div>
            </div>
            <div class="content" v-else>
              <div class="content__left" v-if="item.type === 'layer'">
                <div class="content__name">{{ item.name }}</div>
                <div class="content__material">{{ item.material.name }}</div>
              </div>
              <div class="content__left" v-else>
                <div class="content__name">{{ $t('message.details.junction.fastener') }}</div>
                <div class="content__material">{{ getFastenerName(item.selected) }}</div>
              </div>
              <div
                class="value"
                v-if="
                  item.material &&
                  item.material.valueType &&
                  item.material.value !== 0 &&
                  item.type === 'layer'
                "
              >
                {{ item.material.value }} {{ $t('message.units.mm') }}
              </div>
              <div class="value" v-if="item.type === 'mount'">
                {{ item.height }} {{ $t('message.units.mm') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sidebar">
        <div class="sidebar__items">
          <div class="image unprint" v-if="isJunctionType">
            <JunctionImage :url="img" v-if="img !== 'default'" />
            <div class="default-image" v-else>
              <img src="@/assets/img/icons/uploader--pic.svg" alt="" class="default-image__img" />
            </div>
          </div>
          <div class="image" v-else>
            <SingleSystemSchemeImage ref="schemeComponent" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getItemNumber } from '@/utils/customFunctions'
import { mapState } from 'vuex'
import JunctionImage from '@/components/elements/Junction/JunctionImage'
import SingleSystemSchemeImage from '@/components/smart/System/Scheme/SingleSystemSchemeImage'
export default {
  props: {
    layers: Array,
    type: String,
    img: String
  },
  components: {
    JunctionImage,
    SingleSystemSchemeImage
  },
  methods: {
    getCurrentNumber(parentIteration, childIteration) {
      return getItemNumber(parentIteration, childIteration, this.layers)
    },
    getFastenerName(id) {
      return this.allFasteners.find(p => p.id === id).name
    }
  },
  computed: {
    ...mapState({
      allFasteners: state => state.fasteners
    }),
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    junctionIndex() {
      return this.$route.params.number ? this.$route.params.number : 0
    },
    getLayers() {
      if (this.isJunctionType) {
        const junctionItems = this.layers.map((layer) => layer.items).flat(1).sort((a, b) => a.mark - b.mark)
        return [{ items: [...junctionItems] }]
      }

      return this.layers
    },
    isJunctionType() {
      return this.type === 'junction'
    }
  }
}
</script>

<style scoped lang="sass">
.details-page
  &__structure
    &-content
      display: grid
      +media((grid-template-columns: (320: 1fr, 960: 7fr 1fr 4fr)))
      position: relative
      z-index: 2
      .body
        display: flex
        flex-direction: column
        +media((width: (320: 100%, 650: 92%, 960: 100%)))
        +media((margin: (320: 0, 650: 0 auto, 960: 0)))
        .structure-layers
          display: flex
          flex-direction: column
          &__item
            background: #FFFFFF
            box-shadow: inset 0 rem(-1) 0 #ECECEC
            display: grid
            +media((grid-template-columns: (320: rem(32) 1fr 44fr rem(8), 960: 1fr 13fr)))
            padding: rem(11) 0
            .number
              @extend .fs-12
              color: #d6d6d6
              font-weight: bold
              display: flex
              flex-direction: column
              justify-content: center
              align-items: center
            .content
              display: grid
              +media((grid-column-start: (320: 3, 960: unset)))
              +media((grid-template-columns: (320: 17fr 1fr 8fr, 960: 21fr rem(20) 4fr)))
              &__name
                @extend .fs-14
                font-weight: bold
                color: $black
                margin-bottom: rem(4)
              &__material, &__mark
                @extend .fs-12
              &__mark
                color: $dark-grey
              &__material
                margin-bottom: rem(4)
                color: $light-black
            .value
              @extend .fs-14
              +media((padding-right: (320: rem(16), 960: 0)))
              +media((font-size: (320: rem(12), 960: rem(14))))
              font-weight: bold
              color: $black
              display: flex
              flex-direction: column
              justify-content: center
              +media((align-items: (320: flex-end, 960: flex-start)))
              grid-column-start: 3
      .sidebar
        +media((grid-column-start: (320: 1, 960: 3)))

        &__items
          display: flex
          flex-direction: column
          +media((flex-direction: (320: column-reverse, 960: column)))
          align-items: flex-start
          position: sticky
          top: rem(15)
        .image
          // width: 100%1
          +media((margin: (320: rem(15) auto 0, 960: 0 auto)))
          +media((width: (320: 84%, 960: 100%)))
          // +media((display: (320: none, 960: block)))
        .default-image
          background: #F6F6F6
          box-shadow: inset 0 rem(-1) 0 #ECECEC
          height: rem(176)
          flex-direction: row
          align-items: center
          justify-content: center
          display: flex
        .simple-btn
          font-weight: 500
          +media((width: (320: 100%, 650: 92%, 960: auto)))
          +media((margin: (320: 0, 650: 0 auto, 960: rem(16) 0 0)))
</style>
