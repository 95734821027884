<template>
  <button class="delete" @click="handler">
    <img src="@/assets/img/trash.svg" alt="delete" class="icon" />
  </button>
</template>

<script>
export default {
  name: 'deleteBtn',
  methods: {
    handler() {
      this.$emit('btnClicked')
    }
  }
}
</script>

<style scoped lang="sass">
.delete
  @extend .clear-btn
  transition: .5s
  cursor: pointer
  background: $default
  height: rem(50)
  +media((width: (320: rem(40), 768: rem(50))))
  border-radius: rem(4)
  .icon
    transition: .5s
    filter: invert(71%) sepia(1%) saturate(140%) hue-rotate(314deg) brightness(90%) contrast(73%)
  @media(any-hover: hover)
    &:hover
      background: $red-hover
      .icon
        filter: invert(1)
</style>
