<template>
  <div class="tables-layout">
    <div class="details-title">
      <span class="details-title__text">{{
        $t(`message.details.${placement}.usedMaterials.title`)
      }}</span>
    </div>
    <div v-for="(item, i) in subject" :key="`table-${i}`">
      <div
          class="used-materials__table used-materials__table--total"
          :class="{ active: isTableItemExpanded(i) }"
      >
        <div class="table-head" @click="toggleTableItem(i)">
          <span class="title">{{ $t(`message.details.system.usedMaterials.table.${i}`) }}</span>
          <div
              class="chevron"
              :class="{ 'chevron--active': isTableItemExpanded(i) }"
          >
            <img src="@/assets/img/icons/table-chevron.svg" alt="↓" />
          </div>
        </div>
      </div>
      <slot :name="i" v-if="isTableItemExpanded(i)"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placement: {
      type: String,
      default: 'system'
    },
    subject: {
      type: Object
    },
    index: {
      type: String
    }
  },
  data: () => ({
    expandedTableItems: {}
  }),
  methods: {
    toggleTableItem(index) {
      const key = `table-sector-${this.index}-${index}`
      this.expandedTableItems[key] = !this.expandedTableItems[key]
      this.expandedTableItems = { ...this.expandedTableItems }
    },
    isTableItemExpanded(index) {
      return this.expandedTableItems[`table-sector-${this.index}-${index}`]
    }
  }
}
</script>

<style scoped lang="sass">
@import "~@/assets/sass/result-used-materials.sass"

.tables-layout
  .details-title
    display: grid
    grid-template-columns: 1fr rem(40)
    &__text
      display: flex
      flex-direction: row
      align-items: center
      padding-right: rem(15)
.used-materials__table
      & + .used-materials
        +media((margin: (320: rem(17) 0, 650: rem(17) auto, 960: rem(17) 0)))
</style>
