<template>
  <Modal :isModalOpen="isModalOpen" :isShowClose="true" @close="close">
    <template #body>
      <div class="delete-confirmation">
        <div class="delete-confirmation__title">{{ $t('message.modal.attentionTitle') }}</div>
        <i18n
          :path="bodyText"
          tag="div"
          class="delete-confirmation__text"
        >
          <template #break>
            <br />
            <br />
          </template>
        </i18n>
        <div class="delete-confirmation__buttons">
          <simple-button color="red" @clickedFromSimpleBtn="confirm">
            {{ $t(confirmText) }}
          </simple-button>
          <simple-button color="green" @clickedFromSimpleBtn="close">
            {{ $t(cancelText) }}
          </simple-button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
export default {
  props: {
    isModalOpen: Boolean,
    bodyText: String,
    confirmText: String,
    cancelText: String
  },
  name: 'DeleteConfirmation',
  components: {
    SimpleButton: () => import('@/components/elements/Dom/Simple-button'),
    Modal: () => import('@/components/elements/Modals/Modal')
  },
  methods: {
    close() {
      this.$emit('close')
    },
    confirm() {
      this.$emit('confirm')
      this.$root.onBodyScroll()
    }
  }
}
</script>

<style scoped lang="sass">
.delete-confirmation
  max-width: rem(400)
  padding: rem(25)
  box-sizing: border-box
  &__title
    color: $red
    font-size: rem(24)
    font-weight: bold
    margin-bottom: rem(12)
  &__text
    color: $black
    font-size: rem(14)
  &__buttons
    display: flex
    +media((align-items: (400: center)))
    +media((flex-direction: (320: column, 400: row)))
    margin-top: rem(20)
    .simple-btn
      height: rem(50)
      &:not(:last-child)
        +media((margin: (320: 0 0 rem(6), 400: 0 rem(6) 0 0)))
</style>
