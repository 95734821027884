<template>
  <div class="junction-image" ref="junctionImage">
    <div
      class="dragscroll junction-image__wrapper"
      :style="{ overflow: isDesktop ? 'hidden' : 'auto' }"
      ref="imageWrapper"
      :class="{ grabbing: imageZoom > 1 }"
    >
      <img :src="url" class="img" alt="" :style="{ transform: `scale(${imageZoom})` }" />
    </div>
    <Image-controls
      :imageZoom="imageZoom"
      @increaseZoom="increaseZoom"
      @decreaseZoom="decreaseZoom"
      @updateZoom="updateZoom"
      @showLightBox="isLightBoxShow = true"
    />
    <ImageLightBox @close="close" :isShow="isLightBoxShow" :url="url" :x-position="xPosition" />
  </div>
</template>

<script>
import { scrollWithZoom } from '@/utils/customFunctions'
import dragscroll from 'dragscroll/dragscroll.js'
import device from 'current-device'
import ImageControls from '@/components/smart/uploader/imageControls'
import ImageLightBox from '@/components/smart/uploader/ImageLightBox'
export default {
  props: {
    url: String
  },
  data: () => ({
    imageZoom: 1,
    isLightBoxShow: false,
    isDesktop: '',
    xPosition: 0
  }),
  watch: {
    imageZoom() {
      if (this.imageZoom > 0) {
        scrollWithZoom(this.$refs.imageWrapper, this.imageZoom)
      }
    }
  },
  methods: {
    updateZoom(value) {
      this.imageZoom = value
    },
    increaseZoom() {
      this.imageZoom += 1
    },
    decreaseZoom() {
      this.imageZoom -= 1
    },
    close() {
      this.isLightBoxShow = false
    }
  },
  components: {
    ImageControls,
    ImageLightBox
  },
  mounted() {
    dragscroll.reset()
    device.desktop() ? (this.isDesktop = true) : (this.isDesktop = false)
    this.xPosition = this.$refs.junctionImage.getBoundingClientRect().top
  }
}
</script>

<style scoped lang="sass">
.junction-image
  width: 100%
  overflow: hidden
  &__wrapper
    width: 100%
    line-height: 0
    overflow: hidden
    &.grabbing
      cursor: grab
    img
      width: 100%
      height: auto
      max-width: 100%
      transform-origin: 0 0
</style>
