<template>
  <section class="details-page">
    <div class="container" v-if="allSystems.length !== 0">
      <div class="breadcrumbs unprint">
        <router-link :to="`/${this.$i18n.locale}/result`" class="link__back">
          <img src="@/assets/img/icons/breadcrumbs--back.svg" alt="<" class="icon" />
          {{ $t('message.details.breadcrumb.total') }}
        </router-link>
      </div>
      <div class="title-with-btn">
        <Change-name :name="sectorFromState.name" placement="details" />
        <div class="buttons">
          <SimpleButton
            color="default"
            font="minor"
            additionalClass="cog"
            @clickedFromSimpleBtn="editSystem"
          >
            <template #icon>
              <img src="@/assets/img/icons/cog--red.svg" alt="" class="icon" />
            </template>
            {{ $t('message.details.system.edit') }}
          </SimpleButton>
          <delete-btn @btnClicked="deleteSectorAction" />
        </div>
      </div>
      <div class="details__head-pane common-pane">
        <div class="title">
          {{ $t('message.details.system.head.title') }}
          <span class="print">{{ sectorFromState.name }}</span>
        </div>
        <div class="content">
          <div class="content__item">
            <div class="content__item-title">
              {{ $t('message.details.system.head.system') }}
            </div>
            <div class="content__item-text">
              <img
                src="@/assets/img/icons/details--system--tn.svg"
                alt="TN"
                class="icon"
                v-if="!sectorFromState.isCustom"
              />
              {{ systemName }}
            </div>
          </div>
          <div class="content__item">
            <div class="content__item-title">
              {{ $t('message.details.system.head.square') }}
            </div>
            <div class="content__item-text">{{ sectorFromState.square }} {{ $t('message.units.m2') }}</div>
          </div>
          <div class="content__item">
            <div class="content__item-title">
              {{ $t('message.details.system.head.base') }}
            </div>
            <div class="content__item-text">
              {{ systemBase }}
            </div>
          </div>
        </div>
      </div>
      <div class="details-title unprint" v-if="sectorFromState.junctions.length > 0">
        {{ $t('message.details.system.junctionsNavTitle') }}
      </div>
      <Junctions-nav-list
        :junctions="sectorFromState.junctions"
        v-if="sectorFromState.junctions.length > 0"
        placement="details"
      />
      <Drawing :drawing="showSectorDrawing" placement="sector" />
      <Details-system-structure
        :layers="sectorFromState.layers"
        ref="layersStructure"
        type="system"
      />
      <Details-used-materials-total placement="system" :used-materials="materialsByCurrentSector" />
      <Details-table-layout placement="system" :subject="currentResult.sector" :index="sectorIndex">
        <template v-slot:system>
          <Details-big-table placement="system" :computation="currentResult.sector.system" />
        </template>
        <template v-slot:junctions>
          <Details-simple-table
              :computation="getSectorsJunctions"
              :additional="false"
              v-if="sectorFromState.junctions.length"
          />
        </template>
      </Details-table-layout>
      <ResultSectorsNav />
      <Addons placement="sector" />
    </div>
    <Prefooter @savePdf="savePdf" @saveXls="saveXls" />
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="0.7"
      backgroundColor="#eeeeee"
      :z-index="3"
      :is-full-page="true"
    ></loading>
    <delete-confirmation
      :is-modal-open="isDeleteConfirmationShow"
      :bodyText="'message.deleteSectorConfirmation.text'"
      :confirmText="'message.deleteSectorConfirmation.confirm'"
      :cancelText="'message.deleteSectorConfirmation.cancel'"
      @close="closeDeleteConfirmation"
      @confirm="deleteSector"
    />
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import ChangeName from '@/components/smart/ChangeName'
import JunctionsNavList from '@/components/elements/Junction/JunctionsNavList'
import DetailsSystemStructure from '@/components/smart/Details/DetailsLayersStructure'
import ResultSectorsNav from '@/components/smart/ResultSectorsNav'
import Addons from '@/components/dump/Addons'
import Prefooter from '@/components/dump/Prefooter'
import DetailsUsedMaterialsTotal from '@/components/smart/Details/DetailsUsedMaterialsTotal'
import DetailsBigTable from '@/components/smart/Details/DetailsBigTable'
import DetailsSimpleTable from '@/components/smart/Details/DetailsSimpleTable'
import DetailsTableLayout from '@/components/smart/Details/DetailsTableLayout'
import DeleteBtn from '@/components/elements/Junction/deleteBtn'
import SimpleButton from '@/components/elements/Dom/Simple-button'
import DeleteConfirmation from '@/components/elements/Modals/DeleteConfirmation'
import Drawing from '@/components/dump/DrawingComponent'
import { pages } from '@/utils'

export default {
  data: () => ({
    isDeleteConfirmationShow: false
  }),
  components: {
    DeleteConfirmation,
    SimpleButton,
    DeleteBtn,
    ChangeName,
    JunctionsNavList,
    DetailsSystemStructure,
    ResultSectorsNav,
    Addons,
    Prefooter,
    DetailsTableLayout,
    DetailsBigTable,
    DetailsSimpleTable,
    DetailsUsedMaterialsTotal,
    Drawing
  },
  methods: {
    ...mapActions({
      getPdf: 'getPdf',
      getXls: 'getXls',
      deleteSectorFromDetailsPage: 'deleteSectorFromDetailsPage',
      markCalculationAsSaved: 'markCalculationAsSaved',
      resetUserInfoResults: 'resetUserInfoResults'
    }),
    ...mapMutations({
      COLLAPSE_LAYERS: 'COLLAPSE_LAYERS'
    }),
    editSystem() {
      if (this.isSavedCalculation) {
        this.resetSaveCalculationResult()
      }
      this.$router.push(`/${this.$i18n.locale}/system/${this.sectorIndex}`)
    },
    deleteSectorAction() {
      if (this.isSavedCalculation) {
        this.resetSaveCalculationResult()
      }
      if (this.sectors.length > 1) {
        this.deleteSector()
      } else {
        this.isDeleteConfirmationShow = true
      }
    },
    deleteSector() {
      this.deleteSectorFromDetailsPage({
        sectorIndex: this.sectorIndex,
        lang: this.$i18n.locale
      })
    },
    closeDeleteConfirmation() {
      this.isDeleteConfirmationShow = false
    },
    savePdf() {
      this.getPdf({
        lang: this.$i18n.locale,
        type: 'sector',
        sectorIndex: this.sectorIndex,
        fileName: `${this.$t('message.details.save.fileName')} (${this.sectorFromState.name})`,
        domenName: `${location.origin}/${this.$i18n.locale}`,
        pageTitle: this.$t('message.api.getPdf.firstPageTitleSector'),
        header: {
          name: this.sectorFromState.name,
          systemName: this.systemName,
          square: this.sectorFromState.square,
          base: this.systemBase
        },
        page: pages.details
      })
    },
    saveXls() {
      this.getXls({
        lang: this.$i18n.locale,
        type: 'sector',
        fileName: `${this.$t('message.details.save.fileName')} (${this.sectorFromState.name})`,
        sectorIndex: this.sectorIndex,
        junctionIndex: null,
        title: this.sectorFromState.name,
        page: pages.details
      })
    },
    resetSaveCalculationResult() {
      this.resetUserInfoResults()
      this.markCalculationAsSaved(null)
    }
  },
  mounted() {
    if (this.allSystems.length === 0) {
      this.$router.push(`/${this.$i18n.locale}/`)
    }
    this.COLLAPSE_LAYERS(this.sectorIndex)
  },
  computed: {
    ...mapState({
      resultSectors: state => state.result.sectors,
      isLoading: state => state.pages.details.isLoading,
      materialsBySector: state => state.result.total.materials,
      computation: state => state.result.total.computation,
      sectorMaterials: state => state.result.total.sectorMaterials,
      sectors: state => state.sectors,
      resultTotal: state => state.result.total,
      isSavedCalculation: state => state.user.objectId
    }),
    ...mapGetters({
      allSystems: 'allPublishedSystems'
    }),
    showSectorDrawing() {
      return this.sectorFromState?.sectorImg
    },
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorIndex)
    },
    currentResult() {
      return this.resultSectors[this.sectorIndex]
    },
    getSectorsJunctions() {
      return this.resultTotal.materials[this.sectorIndex].junction
    },
    systemName() {
      return this.sectorFromState.isCustom
        ? this.sectorFromState.customSystemName
        : this.allSystems.find(p => p.id === this.sectorFromState.system).name
    },
    systemBase() {
      const currentLayers = this.sectorFromState.layers
      return currentLayers.find(p => p.isBaseLayer === true).items[0].material.name
    },
    materialsByCurrentSector() {
      const indices = this.computation
        .filter((material) => material.indices?.some((indice) => indice?.sectorIndex === parseInt(this.sectorIndex)))
        .map((material) => ({
          name: material.name,
          indices: material.indices.filter((indice) => indice?.sectorIndex === parseInt(this.sectorIndex))
        }))

      return this.sectorMaterials[this.sectorIndex].map((material) => {
        const materialIndices = indices.find((item) => item.name === material.name)?.indices

        return { ...material, indices: materialIndices }
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.details-page
  .title-with-btn
    @extend %title-with-multiple-btns

  .content__item
    margin-right: 0
    white-space: nowrap
  .junctions-nav__list
    padding-bottom: 0
.title
  .print
    display: none
</style>
