<template>
  <div class="used-materials__table" v-if="usedMaterials.length > 0">
    <div class="details-title" v-if="!isInResultPage">
      {{ $t(`message.details.${placement}.usedMaterials.titleTotal`) }}
    </div>
    <div class="table-head table-head--simple">
      <div class="titles">
        <div class="titles__left">
          <span class="title">
            {{ $t('message.result.usedMaterials.table.material') }}
          </span>
        </div>
        <div class="titles__right" :class="{ 'titles__right--simple': isInResultPage || isJunctionDetailsPage }">
          <span class="title">
            {{ $t('message.result.usedMaterials.table.count') }} <br />
            <span class="description">
              {{ $t('message.result.usedMaterials.table.computation') }}
            </span>
          </span>
          <span class="title">
            {{ $t('message.result.usedMaterials.table.count') }} <br />
            <span class="description description--md">
              {{ $t('message.result.usedMaterials.table.package') }}
            </span>
            <span class="description description--xs">
              {{ $t('message.result.usedMaterials.table.packageMob') }}
            </span>
          </span>
          <span class="title" v-if="isInResultPage || !isJunctionDetailsPage">
            {{ $t('message.result.usedMaterials.table.sectors') }}
          </span>
        </div>
      </div>
    </div>
    <div class="table-body">
      <div
        class="table-body__item table-body__item--simple"
        v-for="(item, i) in filteredMaterials"
        :key="i"
      >
        <div class="table-body__item-left">
          <span class="content">{{ item.name }}</span>
        </div>
        <div class="table-body__item-right" :class="{ 'table-body__item-right--simple': isInResultPage || isJunctionDetailsPage }">
          <span class="content">{{ item.count }} {{ item.units }}</span>
          <span class="content">{{ item.countPackage }} {{ item.units }}</span>
          <span class="content count" v-if="!isInResultPage && item.indices">{{ getIndices(item).length }}</span>
          <button
              v-if="!isInResultPage && item.indices"
              class="chevron"
              :class="{ 'chevron--active': currentTableItemExpand === tableItem(i) }"
              @click="toggleTableItem(tableItem(i))"
          >
            <img src="@/assets/img/icons/table-chevron.svg" alt="↓" />
          </button>
        </div>
        <Result-used-materials-nav
            v-if="currentTableItemExpand === tableItem(i) && !isInResultPage && item.indices"
            :indices="getIndices(item)"
        />
      </div>
    </div>
    <div
      class="table-footer"
      :class="{ active: isShowMore }"
      @click="isShowMore = !isShowMore"
      v-if="isShowToggler"
    >
      <div class="table-footer__content">
        <span class="text">
          <span
            v-text="
              isShowMore
                ? `${$t('message.result.usedMaterials.table.hide')}`
                : `${$t('message.result.usedMaterials.table.show')}`
            "
          ></span>
          {{ usedMaterials.length }}
        </span>
        <img src="@/assets/img/icons/filter--select.svg" alt="↓" class="chevron" />
      </div>
    </div>
    <slot name="sectorDetails"></slot>
    <Message :type="'sectorNote'"/>
  </div>
</template>

<script>
import ResultUsedMaterialsNav from '@/components/smart/Result/ResultUsedMaterialsNav'
import Message from '@/components/elements/Dom/Message'

export default {
  data: () => ({
    count: 15,
    isShowMore: false,
    currentTableItemExpand: null
  }),
  props: {
    isInResultPage: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: 'system'
    },
    usedMaterials: {
      type: Array,
      default: () => []
    },
    isSlicingNeed: {
      type: Boolean,
      default: true
    }
  },
  components: {
    ResultUsedMaterialsNav,
    Message
  },
  computed: {
    isShowToggler() {
      return !this.isInResultPage && this.usedMaterials.length > 6
    },
    isJunctionDetailsPage() {
      return this.placement === 'junction'
    },
    filteredMaterials() {
      if (this.isSlicingNeed) {
        if (this.isShowMore) {
          return this.usedMaterials
        }
        return this.usedMaterials.slice(0, 6)
      } else {
        return this.usedMaterials
      }
    }
  },
  methods: {
    toggleTableItem(val) {
      this.currentTableItemExpand = this.currentTableItemExpand === val ? null : val
    },
    tableItem(index) {
      return `table-item-${index}`
    },
    getIndices(material) {
      return material?.indices ? material.indices : []
    }
  }
}
</script>

<style scoped lang="sass">
@import "~@/assets/sass/result-used-materials.sass"
.details-title
  width: 100%
</style>
